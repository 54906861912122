.referenceContainer {
  margin:var(--mpr-2) 0 ;
}


.referenceWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--mpr-2);
}

.referenceWrapper::-webkit-scrollbar {
  height: 0px;
}

.referenceContainer button {
  transition: all 0.2s ease-in-out;
  width: fit-content;
  background-color: var(--background-Color) !important;
  width: 35px !important;
  height: 35px !important;
  border-radius: 999px !important;
  border: 1px solid var(--primary-Color-Opacity) !important;
}

.referenceContainer button:hover {
  transition: all 0.2s ease-in-out;
  background-color: var(--primary-Color-Opacity) !important;
}

.referenceContainer button:active {
  opacity: 1 !important;
}

.referenceCardParent {
  border-radius: var(--mpr-3);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--fill-Dark-Color);
  width: 300px;
  min-width: 300px;
  overflow: hidden;
  position: relative;
}

.referenceCard {
  padding: var(--mpr-2);
  border-radius: var(--mpr-3);
  display: flex;
  flex-direction: column;
  gap: var(--mpr-3);
  width: 100%;
  height: 100%;
  position: relative;
}

.referenceCard h5 {
  font-weight: 400;
  font-size: 0.9rem;
  height: 36px;
}

.referenceCard a {
  background-color: var(--background-Color);
  font-size: 0.8rem;
  padding: 7px 14px;
  border-radius: 999px;
  overflow: hidden;
  text-wrap: nowrap;
  position: relative;
  color: #005ee8 !important;
  font-weight: 400;
}

.referenceCardGradient {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 10;
  right: 0;
  background: linear-gradient(to left, white, transparent);
  width: 5rem;
}

.darkTheme .referenceCardGradient {
  background: linear-gradient(to left, #1a1a1a, transparent);
}
